import { ISortData, SortOrderEnum } from "components/GoodTable/interfaces";
import { ISelectValue } from "components/Inputs/Select";

import { enumToArray, enumToValuesArray, getEnumValue } from "api/common/enumHelper";

import lang from "./ru";

const shortName = "ru";
const name = "RU-ru";
/*let lang: any = {};
switch (name) {
    default:
        lang = require("./" + shortName).default;
}*/
interface ILangPipes {
    [key: string]: { [key2: string]: string };
}
export function getLangValue(langObject: any, field: string | number, defValue: string = "") {
    if (field in langObject) {
        return langObject[field];
    }
    return defValue;
}
export function getLocaleName(type = "") {
    switch (type) {
        case "short":
            return shortName;
        default:
            return name;
    }
}
export function sprintf(text: string, ...values: (string | number)[]): string {
    if (typeof text == "string" && values?.length) {
        for (const value of values) {
            text = text.replace("%s", value.toString());
        }
    }
    return text;
}
/**
 *
 * @param enumName название enum-а
 * @param valueName название поля
 * @returns
 */
export function getEnumTitle(enumName: string, valueName: string) {
    enumName = enumName.charAt(0).toLocaleLowerCase() + enumName.slice(1);
    if (enumName.endsWith("Enum") || enumName.endsWith("Flag")) {
        enumName = enumName.slice(0, -4);
    }
    valueName = valueName.charAt(0).toLocaleLowerCase() + valueName.slice(1);
    const langPipes = lang.pipes as ILangPipes;
    return !!langPipes?.[enumName]?.[valueName] ? langPipes?.[enumName]?.[valueName] : valueName;
}
export function getEnumTitleValue(enumObject: any, enumName: string, enumValue: number, defaultTitle = "") {
    const value = getEnumValue(enumObject, enumValue);
    if (value !== null) {
        return getEnumTitle(enumName, value);
    }
    return defaultTitle;
}

export function sortArray(
    arr: any[],
    fieldName: string,
    options: Intl.CollatorOptions | undefined = { numeric: true }
) {
    const sorter = getSortArrayCollator(options);
    return arr.sort((a, b) => {
        return sorter.compare(a[fieldName], b[fieldName]);
    });
}
export function getSortArrayCollator(options: Intl.CollatorOptions | undefined = { numeric: true }) {
    return new Intl.Collator(name, options);
}
export function getEnumSelectValues(enums: any, enumName: string, sortParams?: ISortData): ISelectValue[] {
    const enumNames = enumToArray(enums);
    const enumValues = enumToValuesArray(enums);

    const result: ISelectValue[] = [];
    for (let i = 0; i < enumNames.length; i++) {
        result.push({
            id: enumValues[i],
            title: getEnumTitle(enumName, enumNames[i].toString()),
        });
    }

    if (sortParams) {
        const sortedResult = sortArray(result, sortParams.sort);
        return sortParams.direction === SortOrderEnum.Ascending ? sortedResult : sortedResult.reverse();
    }

    return result;
}
export default lang;
