import ruComponents from "./ru.components";
import ruPages from "./ru.pages";
import ruPipes from "./ru.pipes";
import { langActions } from "./ru_actions";

const data = {
    actions: langActions,
    add: "Добавить",
    alert: "Сообщение",
    all: "Все",
    and: "и",
    back: "Назад",
    cancel: "Отмена",
    close: "Закрыть",
    cnt: "Кол-во",
    date: "Дата",
    div: "Кратность",
    error: "Ошибка",
    next: "Далее",
    no: "Нет",
    id: "ID",
    ok: "OK",
    or: "или",
    search: "Поиск",
    save: "Сохранить",
    warning: "Внимание",
    yes: "Да",
    you: "Вы",
    type: "Тип",
    unknown: "Неизвестно",
    message: "Сообщение",
    components: ruComponents,
    selectValue: "Укажите значение",
    pages: ruPages,
    pipes: ruPipes,
    store: {
        user: {
            errors: {
                not_found: "Пользователь не найден",
                blocked: "Пользователь заблокирован",
            },
        },
    },
};
export default data;
