export const langActions = {
    ACTION_ELSE: "Иначе",
    ACTION_ERROR_BAD_CODE: "Недопустимый код",
    ACTION_ERROR_BAD_CODENAME: "Данное действие/условие недопустимо",
    ACTION_ERROR_CATALOG_NOT_FOUND: "Запись не найдена",
    ACTION_ERROR_CODES_NOT_FOUND: "Код не найден",
    ACTION_ERROR_DIV_ZERO: "На ноль делить нельзя",
    ACTION_ERROR_EQS_NOT_FOUND: "Предмет не найден",
    ACTION_ERROR_GAMEID: "Этот код для другого мероприятия",
    ACTION_ERROR_INSERT_CODE: "Укажите код",
    ACTION_ERROR_INSERT_EQ: "Укажите Предмет",
    ACTION_ERROR_INSERT_TEXT: "Укажите текст",
    ACTION_ERROR_INSERT_UID: "Укажите корректный ID участника",
    ACTION_ERROR_NOT_1: "Значение не может быть равно 1",
    ACTION_ERROR_OBJECTS_NOT_FOUND: "Объект не найден",
    ACTION_ERROR_PAGES_NOT_FOUND: "Страница не найдена",
    ACTION_ERROR_PARAMS_NOT_FOUND: "Параметр не найден",
    ACTION_ERROR_PARAMS_SLOT: "Данный предмет не подходит для этого слота",
    ACTION_ERROR_POSITIVE: "Число должно быть больше 0",
    ACTION_ERROR_POSITIVE_ZERO: "Число должно быть не отрицательное",
    ACTION_ERROR_QUESTS_NOT_FOUND: "Задание не найдено",
    ACTION_ERROR_ROLES_NOT_FOUND: "Роль не найдена",
    ACTION_ERROR_SCANER_NOT_FOUND: "Сканер не найден",
    ACTION_ERROR_SOUND_NOT_FOUND: "Звук не найден",
    ACTION_ERROR_STATUS_NOT_FOUND: "Статус не найден",
    ACTION_ERROR_VALUE_NOT_FOUND: "Неизвестный тип действия",
    ACTION_ERROR_VALUES_NOT_FOUND: "Значения не заданы",
    ACTION_ERROR_VALUES_LENGTH: "Не заданы необходимые значения",
    ACTION_IF: "Условие",
    ACTION_IF_1: "Переменные",
    ACTION_IF_1_1: "Статус",
    ACTION_IF_1_1_TEXT: "Статус %s %s",
    ACTION_IF_1_2: "Время",
    ACTION_IF_1_2_TEXT: "Время %s %s",
    ACTION_IF_1_3: "Роль",
    ACTION_IF_1_3_TEXT: "Роль %s %s",
    ACTION_IF_1_4: "Сканер",
    ACTION_IF_1_4_TEXT: "Сканер %s %s",
    ACTION_IF_1_5: "Параметр",
    ACTION_IF_1_5_TEXT: "Параметр %s %s %s",
    ACTION_IF_2: "Предмет",
    ACTION_IF_2_TEXT: "Предмет %s %s",
    ACTION_IF_2_0: "Кол-во",
    ACTION_IF_2_1: "Активирован",
    ACTION_IF_2_2: "Не активирован",
    ACTION_IF_3: "Задание",
    ACTION_IF_3_TEXT: "Задание %s%s",
    ACTION_IF_3_0: "Все",
    ACTION_IF_3_1: "Выполняется",
    ACTION_IF_3_2: "Выполнено",
    ACTION_IF_3_3: "Провалено",
    ACTION_IF_3_4: "Нет",
    ACTION_IF_4: "Дата и время",
    ACTION_IF_4_1: "Время",
    ACTION_IF_4_1_TEXT: "Период %s%s",
    ACTION_IF_4_1_1: "Время",
    ACTION_IF_4_1_2: "С продлением",
    ACTION_IF_4_1_3: "Продление и возврат",
    ACTION_IF_4_1_4: "Отложенное",
    ACTION_IF_4_2: "Дата",
    ACTION_IF_4_2_TEXT: "Дата %s %s",
    ACTION_IF_5: "Оповещение",
    ACTION_IF_5_1: "Оповещать",
    ACTION_IF_5_1_0: "Не оповещать",
    ACTION_IF_5_1_TEXT: "Оповещать %s",
    ACTION_IF_5_2: "Логировать",
    ACTION_IF_5_2_0: "Не Логировать",
    ACTION_IF_5_2_TEXT: "Логировать %s",
    ACTION_IF_6: "Другое",
    ACTION_IF_6_1: "Расстояние",
    ACTION_IF_6_1_TEXT: "Расстояние %s %s м.",
    ACTION_IF_6_2: "Участник",
    ACTION_IF_6_2_TEXT: "Участник %s %s",
    ACTION_IF_6_3: "Уникальное",
    ACTION_IF_6_3_TEXT: "Уникальное",
    ACTION_IF_6_4: "Отправитель",
    ACTION_IF_6_4_TEXT: "Отправитель %s %s",
    ACTION_IF_6_4_0: "Приложение",
    ACTION_IF_6_4_1: "GPS",
    ACTION_IF_6_4_2: "QR-код",
    ACTION_IF_6_4_3: "Сайт",
    ACTION_IF_6_4_4: "Wi-fi",
    ACTION_IF_6_5: "Лотерея",
    ACTION_IF_6_5_TEXT: "Лотерея %s",
    ACTION_IF_VALUE_0: "=",
    ACTION_IF_VALUE_1: "<",
    ACTION_IF_VALUE_2: "<=",
    ACTION_IF_VALUE_3: ">",
    ACTION_IF_VALUE_4: ">=",
    ACTION_IF_VALUE_5: "<>",

    ACTION_THEN: "Действие",
    ACTION_THEN_1: "Переменные",
    ACTION_THEN_1_1: "Статус",
    ACTION_THEN_1_1_TEXT: "Статус = %s",
    ACTION_THEN_1_2: "Время",
    ACTION_THEN_1_2_TEXT: "Время %s %s",
    ACTION_THEN_1_3: "Роль",
    ACTION_THEN_1_3_TEXT: "Роль = %s",
    ACTION_THEN_1_4: "Сканер",
    ACTION_THEN_1_4_TEXT: "Сканер = %s",
    ACTION_THEN_1_5: "Параметр",
    ACTION_THEN_1_5_TEXT: "Параметр %s %s %s",
    ACTION_THEN_1_5_SLOT: "Слот %s",
    ACTION_THEN_1_5_SLOT0: "Свободный",
    ACTION_THEN_2: "Урон",
    ACTION_THEN_2_1: "Урон стандартный",
    ACTION_THEN_2_1_1: "Фиксированный урон",
    ACTION_THEN_2_1_1_TEXT: "Урон %s %s%s %s",
    ACTION_THEN_2_1_2: "Процентный урон",
    ACTION_THEN_2_1_2_TEXT: "Урон% %s %s%s %s",
    ACTION_THEN_3: "Предмет",
    ACTION_THEN_3_TEXT: "Предмет %s%s %s",
    ACTION_THEN_4: "Задание",
    ACTION_THEN_4_0: "Выдать задание",
    ACTION_THEN_4_0_TEXT: "Выдать задание %s%s",
    ACTION_THEN_4_0_0: "Выполняется",
    ACTION_THEN_4_0_1: "Выполнено",
    ACTION_THEN_4_0_2: "Провалено",
    ACTION_THEN_4_1: "Статус задания",
    ACTION_THEN_4_1_TEXT: "Статус задание %s = %s",
    ACTION_THEN_4_1_0: "Выполняется",
    ACTION_THEN_4_1_1: "Выполнено",
    ACTION_THEN_4_1_2: "Провалено",
    ACTION_THEN_4_1_3: "Удалено",
    ACTION_THEN_5: "Объект",
    ACTION_THEN_5_0: "Стандартный объект",
    ACTION_THEN_5_0_TEXT: "Стандартный объект = %s",
    ACTION_THEN_5_0_0: "Базовый объект",
    ACTION_THEN_5_0_1: "Калибровка",
    ACTION_THEN_5_0_2: "Торговец",
    ACTION_THEN_5_1: "Объект",
    ACTION_THEN_5_1_TEXT: "Объект = %s",
    ACTION_THEN_6: "Другое",
    ACTION_THEN_6_1: "Сообщение",
    ACTION_THEN_6_1_TEXT: "Текстовое сообщение",
    ACTION_THEN_6_2: "Звук",
    ACTION_THEN_6_2_TEXT: "Звук %s%s",
    ACTION_THEN_6_3: "Код",
    ACTION_THEN_6_3_TEXT: "Код %s",
    ACTION_THEN_6_4: "Сообщение пользователю",
    ACTION_THEN_6_4_TEXT: "Сообщение пользователю %s",
    ACTION_THEN_6_5: "Сообщение в чат",
    ACTION_THEN_6_5_TEXT: "Сообщение в чат",
    ACTION_THEN_6_6: "Открыть страницу",
    ACTION_THEN_6_6_TEXT: "Страница %s",
    ACTION_THEN_VALUE_0: "=",
    ACTION_THEN_VALUE_1: "+",
    ACTION_THEN_VALUE_2: "-",
    ACTION_THEN_VALUE_3: "*",
    ACTION_THEN_VALUE_4: "/",
    ALL: "Все",
    NO: "Нет",
    YES: "Да",
    ROLE0: "Участник",
    ROLE1: "Администратор",
    slotEmpty: "Слот пуст",
};
