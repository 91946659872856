const ruComponents = {
    confirm: {
        title: "Вы уверены?",
        okText: "Да",
        noText: "Отмена",
    },
    dateRange: {
        invalidDate: "Некорректная дата",
    },
    game: {
        quests: { title: "Задания" },
        eqs: { title: "Предметы" },
        params: { title: "Параметры" },
    },
    goodTable: {
        loading: "Загрузка...",
        rowsPerPage: "Записей на странице",
        noRecords: "Нет записей",
        filters: {
            value: "Значение",
            searchType: "Фильтр",
            asc: "По возрастанию",
            desc: "По убыванию",
        },
        toolbar: {
            search: "Введите текст для поиска",
        },
    },
    loader: {
        defaultText: "Идет загрузка. Подождите...",
    },
    form: {
        isRequired: "Поле обязательно к заполнению",
        minLength: "Поле должно иметь не менее %s символов",
        maxLength: "Поле должно иметь не более %s символов",
        min: "Значение должно быть не меньше %s",
        max: "Значение должно быть не больше %s",
        cancelBtn: "Отмена",
        submitBtn: "Сохранить",
        isUrl: "Укажите корректную ссылку",
        isNumber: "Значение должно быть числом",
        isDate: "Укажите корректную дату",
        isGuid: "Укажите корректный GUID",
        isPhone: "Укажите корректный номер телефона",
        isEmail: "Укажите корректный Email",
        isSelectValue: "Выберите значение из списка",
        pattern: "Поле содержит недопустимые символы",
    },
    iconAdult: {
        text: "18+",
        tooltip: "Маршрут имеет рейтинг 18+",
    },
    inputAutocomplete: {
        noOptionsText: "Ничего не найдено",
        insertSearchText: "Введите текст для поиска",
        selectAll: "Все",
    },
    navigationMenu: {
        main: "Главная",
        games: "Маршруты",
        contacts: "Контакты",
        login: "Войти",
        profile: "Профиль",
        admin: "Админка",
    },
};
export default ruComponents;
