import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import Loader from './components/Loader';
const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Suspense fallback={<div style={{
    height: '100%', display: 'flex', alignItems: 'center',
    justifyContent: 'center'
  }}><Loader animate={false} /></div>}>
    <App /></Suspense>

);

reportWebVitals();
