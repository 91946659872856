const ruPipes = {
    gameStatus: {
        notActive: "Не активен",
        active: "Активен",
        dontPay: "Ожидание оплаты",
        waitConfirmPay: "Ожидание подтверждения оплаты",
        dontSubmitForm: "Не заполнил форму",
        await: "Ожидает",
    },
    questStatus: {
        active: "Выполняется",
        complete: "Выполнено",
        failed: "Провалено",
    },
};
export default ruPipes;
